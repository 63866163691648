// if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
//  $('body').on("mousewheel", function () {
//  event.preventDefault();
//  var wd = event.wheelDelta;
//  var csp = window.pageYOffset;
//  window.scrollTo(0, csp - wd);
//  });
//  }
$(window).on('load', function(){
    var windowHeight = $(window).height(); //ウインドウの高さを取得
    var winW = $(window).width();
    
    lineAnimation();

    $(window).on('scroll', function(){
        lineAnimation();
    });

    function lineAnimation(){
        var scroll = $(window).scrollTop(); //スクロールの位置を取得 
        $('[data-animation], .c-h1, .c-h2, .c-link').each(function(){
            var position = $(this).offset().top;
            if (scroll > position - windowHeight){ //スクロール位置が要素の位置を過ぎたとき
                $(this).addClass('js-active'); //クラス「active」を与える
            }
        });
    }

    if ((winW >= 768) && ($('[data-scrollimage]').length > 0)) {
        var imgH = $('[data-scrollimage]').height();
        var imgW = $('[data-scrollimage]').width();
        var centerY = (1320 * (imgW / 2000) - imgH) / 2;
        var imgY = $('[data-scrollimage]').offset().top;
        $(window).on('scroll', function(){
            parallax();
        });

        function parallax(){
            var scroll = $(window).scrollTop() - 100; //スクロールの位置を取得 
            var diff = parseInt((imgY - scroll) * 0.1 - centerY);
            $('[data-scrollimage]').css({
                'background-position': 'center ' + diff + 'px'
            });
        }
    }
});

